import {Divider, Grid, Typography} from '@mui/material';
import {PaperProps} from '@mui/material/Paper/Paper';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {useMatches} from '../../utils/CommonUtils';
import {TooltipIcon} from '../helperComponents/TooltipIcon';

export type PaperBlockProps = PaperProps & {
    readonly titleBlock: string | JSX.Element;
    readonly tooltipText?: string;
    readonly subtitle?: string;
    readonly boldSubtitle?: boolean;
    readonly subBlock?: boolean;
};

export const ContentBlock: FC<PaperBlockProps> = ({children, titleBlock, tooltipText, subtitle, subBlock, boldSubtitle}) => {
    const matches = useMatches();

    return (
        <Grid
            container
            spacing={1}
            sx={{
                backgroundColor: 'background.default',
                marginBottom: 5,
            }}
        >
            <Grid item xs={12}>
                {titleBlock && (
                    <Typography
                        variant={matches ? 'subtitle1' : 'h6'}
                        sx={{
                            ...(subBlock && {marginLeft: 5}),
                            ...(matches && {fontWeight: 'bold', marginLeft: -1, wordWrap: 'break-word'}),
                        }}
                    >
                        {typeof titleBlock === 'string' ? <FormattedMessage id={titleBlock} /> : titleBlock}
                        {tooltipText && <TooltipIcon text={tooltipText} placement="right-start" color="primary" fontSize="small" />}
                    </Typography>
                )}
                {!!subtitle && (
                    <Typography
                        component="div"
                        variant="body2"
                        sx={(theme) => ({
                            whiteSpace: 'pre-wrap',
                            color: boldSubtitle ? undefined : theme.palette.grey[500],
                        })}
                    >
                        {typeof subtitle === 'string' ? <FormattedMessage id={subtitle} /> : subtitle}
                    </Typography>
                )}
            </Grid>

            {!subBlock && (
                <Grid item xs={12}>
                    <Divider
                        sx={{
                            marginLeft: 0,
                        }}
                    />
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sx={{
                    ...(!subBlock && !matches && {marginLeft: 4}),
                    ...(subBlock && {marginTop: 4}),
                }}
            >
                {children}
            </Grid>
        </Grid>
    );
};

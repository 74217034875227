import {Box} from '@mui/material';
import {ReactNode} from 'react';
import {useIntl} from 'react-intl';
import {theme} from '../../../lib/theme';
import {Div} from '../../styledComponents/Div';
import {TooltipIcon} from '../helperComponents/TooltipIcon';

type TooltipProps = {
    readonly tooltipText: string;
    readonly showTooltip: boolean;
};
type Props = {
    readonly onClick: () => void;
    readonly title: string;
    readonly icon?: ReactNode;
    readonly disabled?: boolean;
    readonly minWidth?: boolean;
};

type BaseProps =
    | ({
          readonly type: 'tooltipButton';
      } & TooltipProps &
          Props)
    | ({readonly type: 'onlyButton'} & Props);
export const ActionCustomButton = (props: BaseProps) => {
    const {onClick, title, disabled, icon, minWidth, showTooltip, tooltipText} = props as BaseProps & TooltipProps;
    const {formatMessage} = useIntl();
    return (
        <Div
            tabIndex={0}
            role="button"
            sx={{
                padding: '10px 20px',
                borderStyle: 'solid',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'fit-content',
                minWidth: minWidth ? 240 : 'fit-content',
                height: 120,
                borderRadius: '10px',
                borderWidth: '2px',
                cursor: 'pointer',
                justifyContent: 'center',
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}26`,
                },
            }}
            color="inherit"
            onClick={disabled ? undefined : onClick}
            data-testid={`${title}-button`}
        >
            {icon}
            <Box component="span" sx={{fontWeight: 'bold', marginTop: 1}}>
                {showTooltip ? (
                    <TooltipIcon text={tooltipText} placement="right-start" color="primary" fontSize="small" showIcon>
                        {formatMessage({id: title})}
                    </TooltipIcon>
                ) : (
                    formatMessage({id: title})
                )}
            </Box>
        </Div>
    );
};
